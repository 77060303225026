import { CurrentEnvironment } from "./../configs/Environment";
export const Environment = {
  Development: "development",
  Staging: "staging",
  Production: "production",
};

function IsDevelopment(): boolean {
  return CurrentEnvironment === Environment.Development;
}

function IsStaging(): boolean {
  return CurrentEnvironment === Environment.Staging;
}

function IsProduction(): boolean {
  return CurrentEnvironment === Environment.Production;
}

export const Environments = {
  IsDevelopment,
  IsStaging,
  IsProduction,
};
